var inputSelectMultiple = function(id) {
  this.inputs = document.querySelectorAll(id);
  
  for(var $i = 0; $i < this.inputs.length; $i++) {
    new Choices(this.inputs[$i], {
      removeItems: true,
      removeItemButton: true,
      placeholder: true,
      placeholderValue: 'Kies uw voorkeur',
      loadingText: 'Laden ...',
      noResultsText: 'Geen resultaten gevonden',
      noChoicesText: 'Geen opties om uit te kiezen',
      itemSelectText: 'Klik om te selecteren',
    });
  }
};