var filterDropdown = function(id) {
  this.filters = document.querySelectorAll(id);

  this.toggleClass = function(list) {
    if(list.classList.contains('is-active')) {
      list.classList.remove('is-active');
    } else {
      list.classList.add('is-active');
    }
  }

  this.addDropdown = function(el) {
    var toggle = el.querySelector('a');
    var list = el.querySelector('ul');

    toggle.addEventListener('click', function() {
      this.toggleClass(list);
      return false;
    }.bind(this));
  }

  for(var $i = 0; $i < this.filters.length; $i++) {
    this.addDropdown(this.filters[$i]);
  }
}