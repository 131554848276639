var mobileNav = function($data) {
  this._body = document.querySelector('body');

  this._init = function($data) {
    // Toggle nav
    this._toggle = document.querySelectorAll($data.toggle);
    this._panel = document.querySelector($data.panel);

    for(var $i = 0; $i < this._toggle.length; $i++) {
      this._addListener(this._toggle[$i], this._panel, true);
    }

    // Toggle subnav
    this._navItems = document.querySelectorAll($data.panel + ' > ul > li');

    for(var $j = 0; $j < this._navItems.length; $j++) {
      var $hasChildren = this._navItems[$j].querySelector('ul');
      
      if($hasChildren) {
        var $toggle = this._navItems[$j].querySelector('a'),
            $panel = this._navItems[$j].querySelector('ul');
        
        this._addListener($toggle, $panel);
      }
    }
  }

  this._addListener = function($toggle, $panel, $overflow = false) {
    $toggle.onclick = function() {
      this._toggleClass($panel, $overflow);
      return false;
    }.bind(this);
  }

  this._toggleClass = function($panel, $overflow) {
    if($panel.classList.contains('is-active')) {
      $panel.classList.remove('is-active');

      if($overflow) {
        this._body.style.overflow = null;
      }
    } else {
      $panel.classList.add('is-active');
      
      if($overflow) {
        this._body.style.overflow = 'hidden';
      }
    }
  }

  this._init($data);
};

new mobileNav({
  toggle: '.js-nav-toggle',
  panel: '.js-nav-panel'
});